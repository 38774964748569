import React, {useEffect, useRef} from 'react';
import {
    Autocomplete,
    Box, Button, Card, CardContent, CardHeader, Checkbox,
    CircularProgress,
    Container,
    FormControl, FormControlLabel, FormGroup,
    InputLabel, LinearProgress, List, ListItem, ListItemText,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import ContinueChat from "./ContinueChat";

async function getFirstMessage(messages, setMessages, setTokens, setIsCogsworthCooking) {
    console.log("Getting first message. This should only log once.")
    const [newMessages, newTokens] = await ContinueChat(messages);
    setMessages(newMessages);
    setTokens(newTokens);
    setIsCogsworthCooking(false)
}

export default function ChatPage() {
    const [messages, setMessages] = React.useState([])
    const [tokens, setTokens] = React.useState(0)
    const [nextUserMessage, setNextUserMessage] = React.useState("")
    const [isCogsworthCooking, setIsCogsworthCooking] = React.useState(true)
    const remainingPower = 1 - (tokens / 3000)
    const outOfPower = remainingPower < 0
    const [connected, setConnected] = React.useState(false)

    return (
        <div style={{margin: 4}}>
            <Container maxWidth="md" disableGutters={true}>
                {!connected && <Box display="flex" flexDirection="column" justifyContent="center" height="100vh">
                    <Stack spacing={3} padding={0}>
                        <Card style={{marginRight: 'auto', marginLeft: 'auto'}}>
                            <CardContent>
                                <Button variant="contained" onClick={async () => {
                                    console.log("Getting first message. This should only log once.")
                                    setConnected(true)
                                    const [newMessages, newTokens] = await ContinueChat(messages);
                                    setMessages(newMessages);
                                    setTokens(newTokens);
                                    setIsCogsworthCooking(false)
                                }}>
                                    Speak with Cogsworth
                                </Button>

                            </CardContent>
                        </Card>
                    </Stack>
                </Box>}
                {connected && <Stack spacing={3} padding={0}>
                    {messages.map((message, index) => (
                        (message.role === 'assistant' || message.role === 'user') &&
                        <Card key={index} style={{
                            maxWidth: '80%',
                            marginLeft: message.role === 'user' ? 'auto' : undefined,
                            marginRight: message.role === 'assistant' ? 'auto' : undefined
                        }}>
                            <CardContent>
                                {message.role === 'assistant' && <Typography sx={{fontSize: 12}} color="text.secondary"
                                                                             gutterBottom>Cogsworth</Typography>}
                                <Typography>{message.content}</Typography>
                            </CardContent>
                        </Card>
                    ))}

                    {isCogsworthCooking && <Card style={{
                        maxWidth: '80%',
                        marginRight: 'auto'
                    }}>
                        <CardContent>
                            <Typography sx={{fontSize: 12}} color="text.secondary" gutterBottom>Cogsworth</Typography>
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{margin: 'auto', display: 'block'}} width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <g transform="translate(50 50)">  <g transform="translate(-19 -19) scale(0.6)"> <g>
                                        <animateTransform attributeName="transform" type="rotate" values="0;45" keyTimes="0;1" dur="0.2s" begin="0s" repeatCount="indefinite"></animateTransform><path d="M31.35997276079435 21.46047782418268 L38.431040572659825 28.531545636048154 L28.531545636048154 38.431040572659825 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 47.3496987939662 L-6.999999999999999 47.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.46047782418268 31.35997276079435 L-21.46047782418268 31.35997276079435 L-28.531545636048154 38.431040572659825 L-38.43104057265982 28.531545636048158 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-47.3496987939662 7.000000000000008 L-47.3496987939662 -6.9999999999999964 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182682 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23" fill="#8c783d"></path></g></g> <g transform="translate(19 19) scale(0.6)"> <g>
                                        <animateTransform attributeName="transform" type="rotate" values="45;0" keyTimes="0;1" dur="0.2s" begin="-0.1s" repeatCount="indefinite"></animateTransform><path d="M-31.35997276079435 -21.460477824182675 L-38.431040572659825 -28.531545636048147 L-28.53154563604818 -38.4310405726598 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999994 -47.3496987939662 L6.999999999999977 -47.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L28.531545636048158 -38.43104057265982 L38.4310405726598 -28.53154563604818 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L47.3496987939662 -6.999999999999997 L47.349698793966205 6.999999999999973 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182682 L31.359972760794346 21.460477824182682 L38.431040572659825 28.531545636048154 L28.531545636048183 38.4310405726598 L21.460477824182703 31.35997276079433 A38 38 0 0 1 6.9999999999999964 37.3496987939662 L6.9999999999999964 37.3496987939662 L6.999999999999995 47.3496987939662 L-7.000000000000009 47.3496987939662 L-7.000000000000007 37.3496987939662 A38 38 0 0 1 -21.46047782418263 31.359972760794385 L-21.46047782418263 31.359972760794385 L-28.531545636048094 38.43104057265987 L-38.431040572659796 28.531545636048186 L-31.359972760794328 21.460477824182703 A38 38 0 0 1 -37.34969879396619 7.000000000000032 L-37.34969879396619 7.000000000000032 L-47.34969879396619 7.0000000000000355 L-47.3496987939662 -7.000000000000002 L-37.3496987939662 -7.000000000000005 A38 38 0 0 1 -31.359972760794346 -21.460477824182682 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23" fill="#8c783d"></path></g></g></g>
                                </svg>
                            </Box>
                        </CardContent>
                    </Card>}

                    <Card style={{marginRight: 'auto'}}>
                        <CardContent>
                            <Typography sx={{fontSize: 12}} color="text.secondary">Power
                                remaining: {Math.max(0, Math.round(remainingPower * 100))}%</Typography>
                        </CardContent>
                    </Card>

                    {!isCogsworthCooking && !outOfPower &&
                        <Card>
                            <CardContent>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <TextField id="userInput"
                                               variant="outlined"
                                               multiline
                                               value={nextUserMessage}
                                               style={{flexGrow: 1}}
                                               onChange={(event) => {
                                                   setNextUserMessage(event.target.value);
                                               }}/>
                                    <Button variant="contained" onClick={async () => {
                                        if (nextUserMessage != "") {
                                            const newMessages = [...messages, {role: 'user', content: nextUserMessage}];
                                            setMessages(newMessages)
                                            setIsCogsworthCooking(true)
                                            const [newNewMessages, newTokens] = await ContinueChat(newMessages);
                                            setMessages(newNewMessages);
                                            setTokens(newTokens)
                                            setNextUserMessage("");
                                            setIsCogsworthCooking(false)
                                        }
                                    }}>
                                        Send
                                    </Button>
                                </Stack>
                            </CardContent>
                        </Card>
                    }

                    {outOfPower &&
                        <Card style={{
                            marginRight: 'auto',
                            marginLeft: 'auto'
                        }}>
                            <CardContent>
                                <Typography>Disconnected. Power loss at target.</Typography>
                            </CardContent>
                        </Card>
                    }
                </Stack>}
            </Container>
        </div>
    );
}