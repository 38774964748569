const {Configuration, OpenAIApi} = require("openai");
import { API } from 'aws-amplify';

export default async function ContinueChat(messages) {
    return ContinueChatRecursive(messages, 3)
}

async function ContinueChatRecursive(messages, numRetries) {
    if (numRetries < 0) throw new Error("max retries reached")

    const apiName = 'speakWithCogsworthApi'; // replace this with your api name.
    const path = '/speakWithCogsworth'; //replace this with the path you have configured on your API
    const myInit = {
        body: {
            messages
        }, // replace this with attributes you need
        headers: {} // OPTIONAL
    };

    let result = null
    try {
        result = await Promise.race([
            API.post(apiName, path, myInit),
            new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 25000))
        ])
    } catch (error) {
        console.log("Caught issue with openai or timeout")
        console.log(error)
        return ContinueChatRecursive(messages, numRetries - 1)
    }

    return [result.messages, result.tokens]
}
