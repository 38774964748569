import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ChatPage from "./ChatPage";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import background from './public/blue_damask.jpg'

const App = () => {
    const theme = createTheme({
        palette: {
            mode: "dark",
            background: {
                paper: '#203141' // card color
            },
            primary: {
                main: '#66ff66' // button color
            },
            text: {
                primary: '#66ff66'

            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
                                body {
                                    background-image: url(${background});
                                }
                                `,
            },
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Router>
                    <Routes>
                        <Route path="/" element={<ChatPage/>}/>
                    </Routes>
                </Router>
            </LocalizationProvider>
        </ThemeProvider>

    );
};

export default App
